import React, { useRef } from "react";
import { useAuth } from "../context/AuthContextes";

export function Login() {
  const emailRef = useRef();
  const passRef = useRef();
  const { login } = useAuth();

  function handleSub(e) {
    e.preventDefault();
    login(emailRef.current.value, passRef.current.value);
  }

  return (
    <div style={styles.container}>
      <div style={styles.loginBox}>
        {/* Logo Section */}
        <div style={styles.logo}>
          <img
src="https://firebasestorage.googleapis.com/v0/b/crmdatabase-8656d.firebasestorage.app/o/logo.png?alt=media&token=1fe4bc20-311d-409a-a0ca-f22c49361a63" // Replace with your logo URL
alt="Logo"
            style={styles.logoImage}
          />
        </div>
        {/* Login Form */}
        <form style={styles.form} onSubmit={handleSub}>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Email</label>
            <input
              ref={emailRef}
              type="email"
              placeholder="Enter your email"
              style={styles.input}
              required
            />
          </div>
          <div style={styles.inputGroup}>
            <label style={styles.label}>Password</label>
            <input
              ref={passRef}
              type="password"
              placeholder="Enter your password"
              style={styles.input}
              required
            />
          </div>
          <button type="submit" style={styles.button}>
            LOGIN
          </button>
        </form>
      </div>
    </div>
  );
}

const styles = {
  // Full-screen gradient background
  container: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: 'url("https://plus.unsplash.com/premium_photo-1664443577580-dd2674e9d359?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")', // Replace with your image URL
    padding: "1rem", // Added padding for smaller screens
    boxSizing: "border-box",
  },
  // Login box with white background
  loginBox: {
    backgroundColor: "#ffffff",
    padding: "2rem",
    borderRadius: "15px",
    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
    width: "100%",
    maxWidth: "400px", // Ensure the width doesn't exceed 400px
    textAlign: "center",
  },
  // Logo styles
  logo: {
    marginBottom: "1rem",
  },
  logoImage: {
    width: "100%", // Adjust size to fit smaller screens
    maxWidth: "200px", // Ensure logo doesn't exceed 200px width
    height: "auto",
  },
  // Form styles
  form: {
    display: "flex",
    flexDirection: "column",
  },
  inputGroup: {
    marginBottom: "1.5rem",
    textAlign: "left",
  },
  label: {
    fontSize: "0.9rem",
    color: "#333",
  },
  input: {
    width: "100%",
    padding: "0.8rem",
    marginTop: "0.5rem",
    fontSize: "0.9rem",
    border: "1px solid #ccc",
    borderRadius: "8px",
    outline: "none",
    boxSizing: "border-box",
    transition: "border-color 0.3s",
  },
  inputFocus: {
    borderColor: "#c71585",
  },
  // Login button styles
  button: {
    width: "100%",
    padding: "0.8rem",
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#ffffff",
    backgroundColor: "#c71585",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#a0136b",
  },
};
