import React, { useRef, useState } from 'react';
import { useAuth } from '../context/AuthContextes';

export function Addusrs() {
  const { writedata_id, writedata_search, uploadstorage } = useAuth();
  const f_name = useRef();
  const l_name = useRef();
  const phone_num = useRef();
  const id_num = useRef();
  const [isHovered, setIsHovered] = useState(false);
  const [Lic_img, setLic_img] = useState();  
  const [Id_img, setId_img] = useState();
  const [error, setError] = useState('');

  async function add() {
    if ( !f_name.current.value || !l_name.current.value || !id_num.current.value || !phone_num.current.value ) {
      setError('Please fill in all the fields.');
      return; 
    }
    let data = {
      first_name: f_name.current.value,
      last_name: l_name.current.value,
      id_number: id_num.current.value,
      phone_number: phone_num.current.value,
      status: false,
    };
    await writedata_id("users", id_num.current.value, data);

    let search = [];
    await writedata_search("users", id_num.current.value, search);

    await uploadstorage(Lic_img, id_num.current.value, "Lic")
    await uploadstorage(Id_img, id_num.current.value, "Id")

    //reset window To-Do       
    

  }

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Add New Customer</h2>

      <div style={styles.formGroup}>
        <label htmlFor="fname" style={styles.label}><b>First Name</b></label>
        <input ref={f_name} type="text" placeholder="Enter first name" style={styles.input} required />
      </div>

      <div style={styles.formGroup}>
        <label htmlFor="lname" style={styles.label}><b>Last Name</b></label>
        <input ref={l_name} type="text" placeholder="Enter last name" style={styles.input} required />
      </div>

      <div style={styles.formGroup}>
        <label htmlFor="id" style={styles.label}><b>ID Number</b></label>
        <input ref={id_num} type="text" placeholder="Enter ID number" style={styles.input} required />
      </div>

      <div style={styles.formGroup}>
        <label htmlFor="phone" style={styles.label}><b>Phone Number</b></label>
        <input ref={phone_num} type="text" placeholder="Enter phone number" style={styles.input} required />
      </div>

      <div style={styles.formGroup}>
        <label>License</label>
        <input
          name="licimg"
          type="file"
          style={styles.input}
          placeholder="non"
          onChange={(e)=>{setLic_img(e.target.files[0])}}
        />
      </div>

      <div style={styles.formGroup}>
        <label>Id image</label>
        <input
          name="idimg"
          type="file"
          style={styles.input}
          placeholder="non"
          onChange={(e)=>{setId_img(e.target.files[0])}}
        />
      </div>

      <br></br>

      {error && <div style={styles.errorMessage}>{error}</div>}
        <div style={styles.buttonContainer}>
          <button
            onClick={add}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={isHovered ? { ...styles.button, ...styles.buttonHover } : styles.button}
          >
            Add
          </button>
        </div>    
        </div>
  );
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    padding: '40px',
    width: '500px',
    margin: '0 auto',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 2, 2, 0.1)',
    position: 'absolute',
    top: '50%',
    left: '50%', 
    transform: 'translate(-50%, -50%)',
  },
  header: {
    textAlign: 'center',
    fontSize: '26px',
    color: '#333',
    marginBottom: '20px',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontWeight: 'bold',
    marginBottom: '5px',
    fontSize: '18px',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontSize: '14px',
    outline: 'none',
    boxSizing: 'border-box',

  },
  button: {
    width: '40%',
    padding: '10px',
    backgroundColor: '#AB439A',
    color :'white',
    border: 'none',
    borderRadius: '20px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.1s ease',
     boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
     justifyContent:'center',


  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', 
    width: '100%',
  },
  buttonHover: {
    backgroundColor: 'black',
  },
  errorMessage: {
    color: 'red',
    fontSize: '14px',
    marginBottom: '10px',
  }
};
